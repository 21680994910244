import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

const FooterMini = () => {
  const query = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        copyrightMessage
        logoDark {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  `);

  const { copyrightMessage, logoDark } = query.sanitySiteSettings;

  return (
    <footer>
      <div className="mx-auto w-full max-w-[1440px] px-4 py-20 sm:px-10 lg:px-4">
        {' '}
        {/* Container with similar classes */}
        <div className="flex items-center justify-between">
          {' '}
          {/* Flex container to align items */}
          <Link to="/" className="inline-block">
            <img
              src={logoDark.asset.gatsbyImageData.images.fallback.src}
              alt="Logo"
              height={40}
              width={123}
              className="opacity-30 grayscale"
            />
          </Link>
          <p className="text-sm text-gray-400">{copyrightMessage}</p>
        </div>
      </div>
    </footer>
  );
};

export default FooterMini;
