/* eslint-disable react/jsx-fragments */
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Header from '@/components/common/v4/header';
import Footer from '@/components/common/v5/footer';
import FooterMini from '@/components/common/v4/footer-mini';
import { responsiveMediaStyles } from '../../utils/responsive-media';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import InfoBar from '../info-bar';
import FooterGenAi2024 from '@/components/common/v4/footer-genai-2024';
import { useStore } from '@/store';
import { useReplaceLinks } from '@/hooks/useReplaceLinks';

type layoutProps = {
  children?: React.ReactNode;
  title?: string;
  description?: string;
  image?: string;
  imageWidth?: string;
  imageHeight?: string;
};

const Layout = ({ children }: layoutProps) => {
  useReplaceLinks();
  const location = useLocation();
  const { closeInfoBar } = useStore();

  const getFooter = () => {
    const isGenAiPage = location.pathname === '/genai-productionize-2-0';
    const isDatabricksAiSummit2024Page =
      location.pathname === '/databricks-summit-2024-prize-giveaway';

    if (isGenAiPage) {
      return FooterGenAi2024;
    }

    if (isDatabricksAiSummit2024Page) {
      return FooterMini;
    }

    return Footer;
  };

  const FooterComponent = getFooter();

  const query = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        description
        siteName
        siteUrl
        infoBar {
          infoBarCtaText
          infoBarCtaUrl
          showInfoBar
          countDown
          _rawText(resolveReferences: { maxDepth: 10 })
        }
      }
    }
  `);

  const data = query.sanitySiteSettings;
  const { showInfoBar } = data.infoBar;

  const isInfoBarVisible = showInfoBar && !closeInfoBar;

  return (
    <Fragment>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <style>{responsiveMediaStyles}</style>
        <script
          async
          src="https://tag.clearbitscripts.com/v1/pk_9b17e3cbc6a1dfd3c88f42273c421a6f/tags.js"
          referrerPolicy="strict-origin-when-cross-origin"
        ></script>
        <script
          type="text/javascript"
          async
          src="https://play.vidyard.com/embed/v4.js"
        ></script>
      </Helmet>
      <div
        className={`flex min-h-full flex-col ${
          isInfoBarVisible ? 'pt-[104px] lg:pt-[7.75rem]' : 'pt-5 lg:pt-[80px]'
        }`}
      >
        {isInfoBarVisible && <InfoBar infoBar={data.infoBar} />}

        <Header showInfoBar={isInfoBarVisible} />
        <div className="grow">{children}</div>
        <FooterComponent />
      </div>
    </Fragment>
  );
};

export default Layout;
