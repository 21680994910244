import React, { useEffect, useState } from 'react';

const calculateTimeLeft = (cntdwnDate) => {
  // let year = new Date(cntdwnDate).getFullYear();
  let difference = +new Date(cntdwnDate) - +new Date();

  let timeLeft = {};

  const daysLeft = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hoursLeft = Math.floor((difference / (1000 * 60 * 60)) % 24);
  const minutesLeft = Math.floor((difference / 1000 / 60) % 60);
  const secondsLeft = Math.floor((difference / 1000) % 60);

  if (difference > 0) {
    timeLeft = {
      days: daysLeft < 10 ? `0${daysLeft}` : daysLeft,
      hours: hoursLeft < 10 ? `0${hoursLeft}` : hoursLeft,
      minutes: minutesLeft < 10 ? `0${minutesLeft}` : minutesLeft,
      seconds: secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft,
    };
  }

  return timeLeft;
};

const TimerBox = ({ time, text }) => {
  return (
    <div className="px-1 md:px-2 lg:px-4">
      <div className="w-16 rounded-lg border border-primary-200 bg-white px-0 py-6 shadow-feature md:w-20 lg:w-28 lg:p-6">
        <span className="bg-gradient-to-br from-orangey-yellow via-reddish-magenta to-purply-blue bg-clip-text font-bold tracking-wider text-transparent antialiased">
          {time}
        </span>
        <p className="text-xs font-normal capitalize md:text-sm">{text}</p>
      </div>
    </div>
  );
};

const EventsTimer = ({ countDown }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(countDown));

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval, idx, arr) => {
    if (!timeLeft[interval]) {
      return;
    }

    if (idx === arr.length - 1) {
      timerComponents.push(
        <TimerBox time={timeLeft[interval]} text={interval} />,
      );
    } else {
      timerComponents.push(
        <>
          <TimerBox time={timeLeft[interval]} text={interval} />
          <div className="">:</div>
        </>,
      );
    }
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(countDown));
    }, 1000);

    return () => clearTimeout(timer);
  });
  return (
    <div className="py-10 lg:py-14">
      <div className="-mx-2 flex flex-row items-center justify-center text-center text-3xl font-bold lg:text-[42px]">
        {timerComponents.length ? timerComponents : ''}
      </div>
    </div>
  );
};

export default EventsTimer;
