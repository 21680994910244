import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '@/components/common/layout';
import Container from '@/components/common/container';
import Speakers from '@/components/events/speakers';
import Agenda from '@/components/events/agenda';
import EventsSignup from '@/components/events/signup';
import EventsHero from '@/components/events/hero';

const Events = () => {
  const query = useStaticQuery(graphql`
    {
      sanityEvents {
        seo {
          metaTitle
          metaDescription
          image {
            asset {
              gatsbyImageData(width: 1280)
              url
            }
          }
        }
        sharableImage {
          asset {
            gatsbyImageData(width: 1200, fit: SCALE)
            _rawMetadata
          }
        }
        eventsHeroSection {
          title {
            _key
          }
          subtitle
          countDown
          _rawTitle(resolveReferences: { maxDepth: 10 })
          sectionCtaLinks {
            ... on SanityExternalLink {
              _type
              label
              url
            }
            ... on SanityInternalLink {
              isCta
              label
              slug {
                current
              }
              _type
            }
          }
        }
        speakersSection {
          speakers {
            role
            name
            link
            company
            image {
              asset {
                gatsbyImageData
              }
            }
          }
          title
        }
        agendaSection {
          subtitle
          title
          agendaItems {
            time
            talk
            speaker
          }
        }
        signUpSection {
          sectionCtaLinks {
            ... on SanityExternalLink {
              label
              url
              _type
            }
            ... on SanityInternalLink {
              _type
              isCta
              label
              slug {
                current
              }
            }
          }
          title {
            _key
          }
          _rawTitle(resolveReferences: { maxDepth: 10 })
        }
      }

      sanitySiteSettings {
        description
        siteName
        siteUrl
        infoBar {
          countDown
        }
      }
    }
  `);

  const data = query.sanityEvents;

  const { fallback, width, height } =
    data.sharableImage.asset.gatsbyImageData.images;

  return (
    <>
      <div className="relative">
        <div className="absolute inset-0 z-0 h-full w-full">
          <div className="container mx-auto h-full w-full max-w-6xl overflow-hidden">
            <div className="h-full w-full translate-y-28 transform bg-[url(/eventsgraphic.svg)] bg-[length:150%] bg-top bg-no-repeat md:translate-y-10 md:bg-[length:100%] lg:bg-contain"></div>
          </div>
        </div>

        <div className="relative z-10">
          <Layout
            title="Galileo Demo Hour"
            description="Hear from NLP practitioners about how they use Galileo today"
            image={data.sharableImage && fallback.src}
            imageWidth={data.sharableImage && width}
            imageHeight={data.sharableImage && height}
          >
            <div className="relative z-10 pb-20 pt-14 md:pb-24 lg:pb-32 lg:pt-28">
              <Container>
                <div className="relative">
                  <EventsHero data={data.eventsHeroSection} />
                  <Speakers data={data.speakersSection} />
                  <Agenda data={data.agendaSection} />
                  <EventsSignup data={data.signUpSection} />
                </div>
              </Container>
            </div>
          </Layout>
        </div>
        <div className="absolute inset-0 z-0 h-full w-full">
          <div className="container mx-auto h-full w-full max-w-6xl overflow-hidden">
            <div className="h-full w-full -translate-y-[11%] transform bg-[url(/events-bottom.svg)] bg-[length:150%] bg-bottom bg-no-repeat md:-translate-y-[12%] md:bg-[length:100%] lg:-translate-y-56 lg:bg-contain"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Events;
