import React from 'react';
import Fade from '@/components/common/fade';

const Agenda = ({ data }) => {
  return (
    <div className="pb-24 lg:pb-36">
      <div className="mx-auto max-w-4xl">
        <Fade>
          <h2 className="text-center text-2xl md:text-[32px] lg:text-[40px]">
            {data.title}
          </h2>
          <p className="mx-auto mt-4 text-center text-base text-grey-700 md:text-lg lg:text-[18px] lg:leading-normal">
            {data.subtitle}
          </p>
        </Fade>

        <div className="relative -mx-4 mt-14 sm:-mx-10">
          <div className="absolute inset-x-10 inset-y-0 z-0 rounded-lg shadow-feature"></div>

          <div className="md:overflow-x-none table-wrap relative z-10 overflow-x-scroll px-4 sm:px-10">
            <table
              style={{ borderSpacing: '0px' }}
              className="w-full min-w-[830px] border-separate rounded-lg text-grey-700"
            >
              {data.agendaItems.map((s, i, a) => {
                if (i === 0) {
                  return (
                    <tr className="overflow-hidden rounded-t-lg">
                      <th className="rounded-tl-lg border-x border-t border-grey-200 bg-primary-100 px-6 py-3 text-left">
                        {s.time}
                      </th>
                      <th className="min-w-[260px] border-x border-t border-grey-200 bg-primary-100 px-6 py-3 text-left">
                        {s.talk}
                      </th>
                      <th className="rounded-tr-lg border-x border-t border-grey-200 bg-primary-100 px-6 py-3 text-left">
                        {s.speaker}
                      </th>
                    </tr>
                  );
                }
                if (i === a.length - 1) {
                  return (
                    <tr className="shadow-feature">
                      <td className="rounded-bl-lg border-x border-y border-grey-200 bg-white px-6 py-3">
                        {s.time}
                      </td>
                      <td className="border-x border-y border-grey-200 bg-white px-6 py-3">
                        {s.talk}
                      </td>
                      <td className="rounded-br-lg border-x border-y border-grey-200 bg-white px-6 py-3">
                        {s.speaker}
                      </td>
                    </tr>
                  );
                }
                return (
                  <tr className="">
                    <td className="border-x border-t border-grey-200 bg-white px-6 py-3">
                      {s.time}
                    </td>
                    <td className="border-x border-t border-grey-200 bg-white px-6 py-3">
                      {s.talk}
                    </td>
                    <td className="border-x border-t border-grey-200 bg-white px-6 py-3">
                      {s.speaker}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agenda;
