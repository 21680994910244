import React from 'react';
import Fade from '@/components/common/fade';
import CallToAction from '../common/call-to-action';

import { PortableText } from '@portabletext/react';
import EventsTimer from './counter';
import GradientText from '../common/gradient-text';

const heroTitlePortableText = {
  block: {
    normal: ({ children }) => <span>{children}</span>,
  },
  marks: {
    highlight: ({ children, value }) => (
      <GradientText className="block md:inline" hoverable={false}>
        {children}
      </GradientText>
    ),
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined;
      return (
        <a href={value.href} rel={rel}>
          {children}
        </a>
      );
    },
  },
};

const EventsHero = ({ data }) => {
  const date = data.countDown;
  const event = new Date(date);
  const stringPST = event.toLocaleString('en-US', {
    timeZone: 'PST',
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  });
  const eventUTC = date;

  return (
    <Fade>
      <h1 className="mx-auto max-w-[90%] text-center text-[48px] leading-tight md:max-w-4xl md:text-[56px] lg:text-[72px] xl:text-[92px]">
        <PortableText
          value={data._rawTitle}
          components={heroTitlePortableText as any}
        />
      </h1>
      <p className="mt-4 text-center text-xl font-bold md:mt-0 lg:text-2xl">
        {data.subtitle}
      </p>
      {date && (
        <div>
          <EventsTimer countDown={eventUTC} />
          <p className="text-center font-bold">{stringPST}</p>
        </div>
      )}
      <div>
        <div className="-mx-2 mt-4 flex flex-wrap justify-center text-center">
          {data.sectionCtaLinks &&
            data.sectionCtaLinks.map((link, index) => (
              <div className="w-auto p-2">
                <CallToAction
                  link={link}
                  index={index}
                  className="min-w-[220px]"
                />
              </div>
            ))}
        </div>
      </div>
    </Fade>
  );
};

export default EventsHero;
