import React from 'react';
import Fade from '@/components/common/fade';
import { PortableText } from '@portabletext/react';
import CallToAction from '../common/call-to-action';

const heroTitlePortableText = {
  block: {
    normal: ({ children }) => <span>{children}</span>,
  },
  marks: {
    highlight: ({ children, value }) => (
      <span className="bg-gradient-to-br from-reddish-magenta via-reddish-magenta to-purply-blue bg-clip-text text-transparent antialiased">
        {children}
      </span>
    ),
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined;
      return (
        <a href={value.href} rel={rel}>
          {children}
        </a>
      );
    },
  },
};

const EventsSignup = ({ data }) => {
  return (
    <div className="py-16 md:py-20 xl:py-24">
      <div className="mx-auto max-w-2xl">
        <Fade>
          <h2 className="text-center text-2xl leading-tight md:text-[32px] lg:text-[40px]">
            <PortableText
              value={data._rawTitle}
              components={heroTitlePortableText as any}
            />
          </h2>
          <div className="-mx-2 -my-2 mt-4 flex flex-wrap justify-center text-center">
            {data.sectionCtaLinks &&
              data.sectionCtaLinks.map((link, index) => (
                <div className="w-auto px-2 py-2" key={index}>
                  <CallToAction
                    link={link}
                    index={index}
                    className="min-w-[160px]"
                  />
                </div>
              ))}
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default EventsSignup;
