import React from 'react';

type gradientTextProps = {
  children: string | React.ReactNode;
  className?: string;
  hoverable?: boolean;
  props?: any;
};

const GradientText = ({
  children,
  hoverable,
  className,
  ...props
}: gradientTextProps) => {
  return (
    <span
      className={`bg-gradient-to-br from-y via-rm to-pb bg-clip-text text-transparent antialiased ${
        hoverable
          ? 'hover:from-blue-600 hover:via-purple-500 hover:to-pink-600'
          : ''
      } ${className}`}
      {...props}
    >
      {children}
    </span>
  );
};

export default GradientText;
