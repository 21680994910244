import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import GalileoLogo from '@/assets/genai-productinize-2024/galileo-logo';

const FooterGenAi2024 = () => {
  const query = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        copyrightMessage
        logoDark {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  `);

  const { copyrightMessage, logoDark } = query.sanitySiteSettings;

  return (
    <footer className="bg-[#1A1A1A]">
      <div className="mx-auto w-full max-w-[1440px] px-4 pb-14 pt-10 md:py-24">
        {' '}
        {/* Container with similar classes */}
        <div className="flex flex-wrap items-center justify-between gap-4">
          {' '}
          {/* Flex container to align items */}
          <Link to="/" className="inline-block">
            <GalileoLogo fillColor="#BEC0CC" className="w-24" />
          </Link>
          <p className="text-sm font-normal leading-6 text-[#ccc]">
            {copyrightMessage}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterGenAi2024;
