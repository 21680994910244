import React from 'react';

type callToActionProps = {
  link?: {
    _type: string;
    url?: string;
    label: string;
    slug?: {
      current: string;
    };
  };
  index: number;
  className?: string;
};

const CallToAction = ({ link, index, className }: callToActionProps) => {
  if (link._type === 'internalLink') {
    return (
      <a
        href={`${link.slug.current}`}
        className={` ${className} ${
          index === 0
            ? 'bg-primary-600 text-white'
            : 'bg-white text-primary-600 hover:bg-gray-100'
        } inline-block rounded-lg border border-primary-600 px-6 py-2.5 text-white hover:bg-opacity-80`}
      >
        {link.label}
      </a>
    );
  }

  if (link._type === 'externalLink') {
    return (
      <a
        href={link.url}
        target="_blank"
        rel="noopener noreferrer"
        className={` ${className} ${
          index === 0
            ? 'bg-primary-600 text-white'
            : 'bg-white text-primary-600 hover:bg-gray-100'
        } inline-block rounded-lg border border-primary-600 px-6 py-2.5 hover:bg-opacity-80`}
      >
        {link.label}
      </a>
    );
  }
};

export default CallToAction;
